import React from 'react';
import './app.css';
const forecast_img = require('./assets/forecast.svg');
const logo_forecast = require('./assets/logo-forecast.svg');
const logo_img = require('./assets/logo-umbrella.svg');

export default function App() {
	return (
		<div>
			<img src={logo_forecast} id={'forecast'} />
			{/* <img src={logo_img} id={'logo'} /> */}
			<div id={'logo'}>&copy; Forecast Worldwide 2022</div>
		</div>
	)
}
